@import url("https://rsms.me/inter/inter.css");

/**
 * Main wrapper
 */

.select-search-container {
  width: 100%;
  position: relative;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
  box-sizing: inherit;
}

.select-search-input {
  position: relative;
  z-index: 1;
  display: block;
  height: 60px;
  width: 100%;
  padding: 12px 12px 12px 65px;
  border-radius: 40px;
  outline: none;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 24px;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search-input[readonly] {
  cursor: pointer;
}

.select-search-is-disabled .select-search-input {
  cursor: not-allowed;
}

.select-search-select {
  background-color: white;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  overflow: auto;
  max-height: 300px;
}

.select-search-select {
  position: absolute;
  z-index: 2;
  top: 58px;
  right: 0;
  left: 0;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus
  .select-search-select {
  display: block;
}
.select-search-container:not(.select-search-is-multiple).select-search-has-focus
  .select-search-input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.select-search-options {
  list-style: none;
  min-height: 100px;
}

.select-search-options:not(:has(li)) {
  position: relative;
  &::after {
    content: "Not found";
    position: absolute;
    top: 40px;
    left: 40%;
    color: #7f8186;
  }
}

.select-search-option {
  display: block;
  height: 70px;
  border: 0px solid #d9d9d9;
  border-top-width: 1px;
  width: 100%;
  padding: 10px 40px;
  outline: none;
  font-size: 14px;
  font-weight: 400 !important;
  text-align: left;
  line-height: 24px;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
  cursor: not-allowed;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
  background-color: #f4f6f6;
}

.select-search-is-selected {
  font-weight: 600 !important;
  color: #3e64ff !important;
  font-size: 16px !important;
}

.select-search-group-header {
  font-size: 12px;
  text-transform: uppercase;
  background-color: #f4f6f6;
  color: #7f8186;
  letter-spacing: 0.1rem;
  padding: 10px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
  margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
  margin-bottom: 10px;
}
