.not_found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100vh;
  background: #f7f8ff;
  padding: 1.2rem;
}

.error-500-title {
  font-size: 3.6rem;
  line-height: 3.6rem;
  text-align: center;
}
.background-500-container {
  position: relative;
}
.title-container {
  top: 25%;
  right: 12%;
  left: 0%;
}

.error-500-subtitle {
  font-size: 1.5rem;
  line-height: 2.8rem;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  max-width: 65%;
}

@media screen and (max-width: 1023px) {
  .background-500 {
    display: none !important;
  }
  .title-container {
    max-width: 100%;
  }

  .error-500-subtitle {
    max-width: 100%;
  }
}
